<template>
  <div>
    <div
      class="hero-image"
      :style="{ backgroundImage: 'url(' + bgImages[0] + ')' }"
      id="slider-1"
    >
      <div class="hero-text">
        <h1>Improve Your Wellbeing With Amchara</h1>
      </div>
    </div>

    <featured-in></featured-in>

    <div class="body-content mx-auto">
      <!-- Kickstart Your Helth / Who Our Retreats Are For Section -->
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-9 mx-auto mb-5 mt-5 text-center">
            <p class="mb-5">
              <strong>Research into mindfulness and meditation has demonstrated
many proven health benefits, including managing your stress response,
improving your decision-making abilities, boosting creativity
and helping you to find greater presence in life.</strong>
            </p>
            <p>
              An Amchara detox retreat is not only a physical detox but also an opportunity to detox your mind.</p>
<p>You will be immersed in a supportive and nurturing environment that enables you to switch off,
relax, detox and kickstart your health journey.</p>
<p>The tranquil settings of Amchara Somerset and Amchara Gozo are perfect for
you to practice mindfulness, meditation and breathing exercises.</p>
<p>Your heart rhythm patterns provide a valuable insight into your inner state. Through
self-regulation techniques which are simple to learn and carry out at any time, heart-focused
breathing can help reduce stress and alleviate anxiety. We incorporate heart-focused, deep
breathing exercises into our daily yoga sessions.</p>
<p>Promoting your emotional health is a key aspect of achieving overall body health.</p>
            </p>
          </div>
        </div>
      </div>


      <hr class="mx-auto mb-5" width="80%" />


      <div class="container-fluid">
        <div class="row">
          <div class="col-md-9 mx-auto pb-lg-30 text-center">
            <div class="green-header-3-text mb-5"><strong>Over the last 10 years, we have been helping people like you to
achieve their health goals with our Personalised Health approach</strong></div>
            <ul class="centered-bullets">
              <li class="p-list-item-centered">Enjoy freshly prepared food and juices tailored for your nutritional needs</li>
<li class="p-list-item-centered">Revitalise and re-energise your body and mind with state of the art spa facilities, health treatments and exercise classes</li>
<li class="p-list-item-centered">Learn and grow with experienced Personalised Health practitioners' 1:1 psychological support, health and wellbeing coaching, educational talks plus raw food preparation classes</li>
<li class="p-list-item-centered">Feel empowered to make long-lasting, sustainable changes to your daily life and health outcomes</li>
            </ul>
          </div>
        </div>
      </div>

      <div class="container-fluid">
        <div class="row">
          <div class="col-12 mt-5 text-center">
<img
              class="img-fluid"
              src="@/assets/images/wellbeing-collage.jpg"
              alt="Improve Your Wellbeing With Amchara"
            />
            </div>
        </div>
      </div>

        <div class="row">
          <div class="col-md-8 mb-4 mx-auto">
            <div class="mt-3 mb-3">&nbsp;</div>
            <hr class="mx-auto" />
          </div>
        </div>

        <div class="row">
          <div class="col-md-8 mx-auto">
            <enquiry-form
              :source="'wellbeing_form'"
              :traffic_campaign="traffic_campaign"
              :traffic_source="traffic_source"
            ></enquiry-form>
          </div>
        </div>

        <div class="row">
          <div class="col-md-8 mb-4 mx-auto">

            <div class="mt-3 mb-3">&nbsp;</div>
            <hr class="mx-auto" />
          </div>
        </div>

      <locations></locations>
      <div class="mt-3 mb-3">&nbsp;</div>
      <hr class="mx-auto" width="80%" />
      <trip-advisor></trip-advisor>
      <div class="mt-3 mb-3">&nbsp;</div>
      <hr class="mx-auto" width="80%" />
      <featured-mags></featured-mags>

      <hr width="80%" class="mt-5 mx-auto" />
      <testimonials></testimonials>

      <div class="mt-3 mb-3">&nbsp;</div>
    </div>
    <newsletter-cta></newsletter-cta>
  </div>
</template>

<script>
import FeaturedIn from "./page-partials/FeaturedIn";
import Juicery from "./page-partials/Juicery";
import FeaturedMags from "./page-partials/FeaturedMags";
import Locations from "./page-partials/Locations";
import TripAdvisor from "./page-partials/TripAdvisor";
import Testimonials from "./page-partials/Testimonials";
import Instagram from "./page-partials/Instagram";
import NewsletterCta from "./page-partials/NewsletterCta";
import EnquiryForm from "./page-partials/EnquiryForm";

export default {
  metaInfo() {
    return {
      vmid: this.$route.path,
      name: this.$route.path,
      title: this.$store.seo_metas.find(
        (i) => i.slug == this.$route.path.replace("/", "")
      ).title,
      content: this.$store.seo_metas.find(
        (i) => i.slug == this.$route.path.replace("/", "")
      ).description,
      // titleTemplate: null,
    };
  },
  data() {
    return {
      blogPosts: [],
      bgImages: [require("@/assets/images/banners/wellbeing-banner.jpg")],
      traffic_campaign: sessionStorage.getItem('traffic_campaign'),
      traffic_source: sessionStorage.getItem('traffic_source'),
    };
  },
  mounted() {
    // function changeTheSlider(){
    // if($('#slider-1').is(":visible")){
    //     console.log('yup')
    //     $('#slider-1').slideUp()
    //     $('#slider-2').slideDown()
    //     return true;
    // }
    // if($('#slider-2').is(":visible")){
    //     $('#slider-2').slideUp()
    //     $('#slider-1').slideDown()
    //     return true;
    // }
    // }
    // window.setInterval(function(){
    //   /// call your function here
    //   changeTheSlider()
    // }, 6000);
  },
  components: {
    FeaturedIn,
    Juicery,
    FeaturedMags,
    Locations,
    TripAdvisor,
    Testimonials,
    Instagram,
    NewsletterCta,
    EnquiryForm,
  },
};
</script>

<style>
</style>