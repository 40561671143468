<template>
  <!-- Personalised For You Section -->
  <div class="container-fluid mb-5">
    <div class="row">
      <div class="col-md-9 mx-auto pb-lg-30 text-center">
        <h3>Coming Soon</h3>
        <div class="green-header-2-text">Amchara Juicery</div>
        <p>Cold-pressed nutritious juices delivered straight to your door.</p>
        <p>Created by Amchara's expert in-house health team.</p>
        <p>Enjoy a range of fresh, organic juice cleanses at home.</p>
        <div class="mt-5">
          <img
            width="592"
            src="@/assets/images/juicery/juice bottles-01.jpg"
            alt="Amchara juicery bottles"
            class="img-fluid"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>