<template>
  <div>
    <form @submit.prevent="enquire" autocomplete="off" id="enquiry-form">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group mb-3">
            <label for="firstNameInput">
              First Name
              <span style="color: red">*</span>
            </label>
            <input
              type="text"
              class="form-control mt-1"
              id="firstNameInput"
              v-model="form.first_name"
            />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="surnameInput">
              Surname
              <span style="color: red">*</span>
            </label>
            <input
              type="text"
              class="form-control mt-1"
              id="surnameInput"
              v-model="form.last_name"
            />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="form-group mb-3">
            <label for="emailInput">
              Email
              <span style="color: red">*</span>
            </label>
            <input
              type="email"
              class="form-control mt-1"
              id="emailInput"
              v-model="form.email"
            />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="telephoneInput">
              Telephone
              <span style="color: red">*</span>
            </label>
            <input
              type="text"
              class="form-control mt-1"
              id="telephoneInput"
              v-model="form.phone"
            />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <label for="questionsTextarea">Any Questions?</label>
        </div>
        <div class="col-md-9 my-auto">
          <textarea
            class="form-control mt-1"
            id="questionsTextarea"
            v-model="form.questions"
            style="height: 78px; resize: none"
          ></textarea>
        </div>
        <div class="col-md-3 my-5 my-md-auto">
          <!-- <vue-recaptcha
                    v-show="canSubmit"
                    class="mb-4"
                    sitekey="6LdCGVMUAAAAAAI5h_FtbkmybCkOPtLGQJLWhZxT"
                    :loadRecaptchaScript="true"
          ></vue-recaptcha>-->
          <button
            type="submit"
            class="btn btn-success"
            data-callback="onSubmit"
            style="padding: 10px 40px;"
            :disabled="!canSubmit || busy"
          >
            <i class="far fa-spinner fa-spin me-2" v-if="busy"></i>Submit
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import VueRecaptcha from "vue-recaptcha";

export default {
  props: ["traffic_campaign", "traffic_source", "source"],
  data() {
    return {
      busy: false,
      form: {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        questions: "",
        source: this.source ? this.source : "web_form",
        traffic_campaign: this.traffic_campaign? this.traffic_campaign : null,
        traffic_source: this.traffic_source ? this.traffic_source : null,
      },
      canSubmit: false,
    };
  },
  methods: {
    enquire() {
      this.busy = true;
      this.$axios
        .post(process.env.VUE_APP_API_URL + "/enquiries", this.form)
        .then(({ data }) => {
          this.busy = false;
          if (data.message == "enquired") {
            this.$router.push(
              "/thanks-for-your-enquiry?enquiry=" + data.enquiry.id
            );
          } else {
            this.$router.push("/");
          }
        });
    },
  },
  mounted() {},
  watch: {
    form: {
      handler: function (val) {
        if (
          val.first_name.length > 1 &&
          val.last_name.length > 1 &&
          val.email.length > 5 &&
          val.phone.length > 7
        ) {
          this.canSubmit = true;
        } else {
          this.canSubmit = false;
        }
      },
      deep: true,
    },
  },
  components: {
    VueRecaptcha,
  },
};
</script>

<style></style>
